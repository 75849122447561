/**
 * EasyZoom core styles
 */
.easyzoom {
	position: relative;

	/* 'Shrink-wrap' the element */
	display: inline-block;
	*display: inline;
	*zoom: 1;
}

.easyzoom img {
	vertical-align: bottom;
}

.easyzoom.is-loading img {
	cursor: progress;
}

.easyzoom.is-ready img {
	cursor: crosshair;
}

.easyzoom.is-error  img {
	cursor: not-allowed;
}

.easyzoom-notice {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 150;
	width: 10em;
	margin: -1em 0 0 -5em;
	line-height: 2em;
	text-align: center;
	background: #FFF;
	box-shadow: 0 0 10px #888;
}

.easyzoom-flyout {
	position:absolute;
	z-index: 100;
	overflow: hidden;
	background: #FFF;
}

/**
 * EasyZoom layout variations
 */
.easyzoom--overlay .easyzoom-flyout {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.easyzoom--adjacent .easyzoom-flyout {
	top: 0;
	left: 100%;
	width: 100%;
	height: 100%;
	margin-left: 20px;
}
